// Pseudo Element - Scaffolding
@mixin pseudo-element {
  content: "";
  display: block;
  position: absolute;
}

html,
body {
  overflow-x: hidden;
}

@media (min-width: 1025px) {
  // Header - Centred Logo
  .pageHeader .headerContent .mainCallToAction {
    justify-content: space-between;
    a.mainLogo {
      order: 2;
      margin-right: 0;
    }
    ul.socialIcons {
      order: 1;
      margin-right: 0;
    }
    .mainCallToActionButtons {
      order: 3;
      width: 225px;
    }
  }

  // Navigation - Oblique Line
  .menuMain {
    position: relative;
    z-index: 1;
  }
  .menuMain:after {
    @include pseudo-element;
    z-index: -1;
    width: 101%;
    height: 100%;
    left: 0;
    transform: rotate(-0.44deg);
    bottom: 0;
    background-color: $nav-background-colour;
    transform-origin: bottom right;
  }

  // Carousel controls - Appear on hover
  .carouselControls {
    display: none;
  }
  .carousel:hover {
    .carouselControls {
      display: flex;
    }
  }

  // Carousel - Oblique Line
  .carousel {
    position: relative;
    overflow: hidden;
  }
  .carousel:after {
    @include pseudo-element;
    z-index: 1;
    width: 101%;
    height: 10%;
    left: 0;
    transform: rotate(-2deg);
    bottom: 0;
    background-color: $white;
    transform-origin: bottom right;
  }

  // HeaderText - Desktop
  body:not(.pf_noHeaderMedia) .headerText {
    position: relative;
  }
}

// Youtube autoplay video in Carousel - Settings
.carousel .carouselSlideTitle {
  position: relative;
  overflow: hidden;
}
.carousel .yt-container {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  @media (max-width: 1024px) {
    pointer-events: all;
  }
}

// Buttons - Geometric shape + Gradient
//.cta-button,
.button,
button,
.button.managerEditBtn,
span.readMore,
.addToCalender,
a.cc-btn {
  clip-path: polygon(2% 6%, 97% 0, 100% 100%, 0% 97%);
  background: $green;
  //background-position: left;
  //background-size: 200%;
  &:hover {
    background-color: #014705;
    border-color: #014705;
  }
}
// Burger Button - Geometric shape + Gradient
.menuMainAlt {
  clip-path: polygon(2% 6%, 97% 0, 100% 100%, 0% 97%);
  // background: $green;
  // color: $yellow !important;
  // background-position: left;
  // transition: 300ms all ease !important;
  // background-size: 200%;
  // &:hover {
  //   background-position: right;
  //   transition: 300ms all ease !important;
  // }
  @media (max-width: 480px) {
    clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 5% 95%);
  }
  &:hover {
    background-color: #014705;
    border-color: #014705;
  }
}

// Donate button - Gradient
// a.cta-button.donate,
// a.button.donateBtn,
button.register,
button.donate {
  background: $gradient-dark-blue-blue-dark-blue;
  color: $white !important;
  background-position: left;
  transition: 300ms all ease !important;
  background-size: 200%;
  &:hover {
    background-position: right;
    transition: 300ms all ease !important;
  }
}

// Social Icons - Mobile
.menuMain .mainCallToAction .socialIcons a:before {
  color: $white;
}

// Carousel - ReadMore Button - Mobile
@media (max-width: 1024px) {
  .carousel span.readMore {
    background: $white;
    color: $green;
    &:hover {
      color: $white;
      background: #131223;
    }
  }
}

// Stats - Read More button
.homeImpactWrapper {
  align-items: center; // Fixes an IE stretching issue
  .cta-button {
    background: $white;
    color: #93cb27;
    margin: 0 auto;
    &:hover {
      background: transparent;
      color: $white;
    }
  }
}

// Blockquote - Geometric shape
blockquote {
  //color: $white;
  clip-path: polygon(2% 6%, 97% 0, 100% 100%, 0% 97%);
}

// HomeFeatures - Oblique line
.homeFeaturesWrapper {
  position: relative;
}
.homeFeaturesWrapper:before {
  @include pseudo-element;
  z-index: -1;
  width: 35vw;
  height: 70vw;
  background-color: $grey-lightest;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: rotate(35deg);
  @media (max-width: 768px) {
    height: 200vw;
    width: 70vw;
  }
}
.homeFeaturesWrapper:after {
  @include pseudo-element;
  z-index: -1;
  width: 55vw;
  height: 70vw;
  background-color: $grey-lightest;
  top: -12.5%;
  right: 0;
  transform-origin: top right;
  transform: rotate(-12.5deg);
  @media (max-width: 768px) {
    height: 400vw;
    top: 23.5%;
  }
}

// Home Intro - Image
.homeIntro img {
  max-width: 270px;
  transform: translateX(20px);
}

// Card DetailWrapper - Geometric shape
.homeFeature,
.feedItem,
.listedPost {
  .feedItemDetailsWrapper,
  .homeFeatureDetailsWrapper,
  .listedPostText {
    clip-path: polygon(2.5% 2.5%, 97.5% 0%, 100% 97.5%, 0% 100%);
  }
  &:nth-of-type(even) {
    .feedItemDetailsWrapper,
    .homeFeatureDetailsWrapper,
    .listedPostText {
      clip-path: polygon(0% 2.5%, 97.5% 5%, 100% 97.5%, 2.5% 97.5%);
    }
  }
}

// Home Stats - Oblique line
@media (min-width: 769px) {
  .homefeaturecategory-homeboximpactstats:before {
    @include pseudo-element;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 101%;
    height: 5%;
    transform-origin: top left;
    transform: rotate(1deg);
    background-color: $white;
  }
}

.homeFeedBox2 {
  padding-bottom: 0;
}
// HomeFeeds - Oblique line
.homeFeedsWrapper {
  overflow: hidden;
}
.homeFeedBox3 {
  z-index: 1;
  padding-top: 5rem;
}
.homeFeedBox3:after {
  @include pseudo-element;
  z-index: -1;
  width: 60vw;
  height: 60vw;
  background-color: $grey-lightest;
  bottom: 0;
  left: 0%;
  transform-origin: bottom;
  transform: rotate(-22.5deg) translateY(50%);
  @media (max-width: 768px) {
    left: -5%;
    width: 1000px;
    height: 1000px;
    transform-origin: center left;
  }
}

// Home Feed Snippet - Quick Giving Panel
.homeFeedBox2.homeFeedsnippets {
  position: relative;
  z-index: 2;
  h3.feedsTitle {
    display: none;
  }
  .feedItemDetailsWrapper {
    clip-path: none;
  }
  .quickGivingPanel {
    margin: 0 auto;
  }
  .quickGivingPanel .button {
    margin: $spacer auto 0;
  }
  ul.feedList {
    max-width: 100%;
    background-color: $grey-lightest;
    padding-top: $spacer * 2.25;
    position: relative;
    margin-top: -2.5rem;
  }
  ul.feedList:before {
    @include pseudo-element;
    z-index: 1;
    top: 0;
    right: 0;
    width: 102%;
    height: 10%;
    transform-origin: bottom right;
    transform: rotate(1deg);
    background-color: $white;
  }
}

// Footer - Mailchimp
.footerBox.NewsletterSign-up {
  position: relative;
  &:after {
    @include pseudo-element;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 101%;
    height: 10%;
    transform-origin: bottom right;
    transform: rotate(-1deg);
    background-color: $footer-background-colour;
  }
  #mc_embed_signup_scroll {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 0 $site-bleed;
    > h2 {
      width: 100%;
      margin-bottom: $spacer;
      font-size: $font-size-h3;
    }
    .mc-field-group {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0 20px;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: center;
      .mc-field-group {
        margin: 0;
        width: 100%;
        margin-bottom: $spacer * 0.5;
      }
    }
  }
}

// https://www.bugherd.com/projects/181398/tasks/31
// Trying to make this less weird
@media (min-width: 866px) and (max-width: 1032px) {
  #mc_embed_signup_scroll > .clear {
    margin: 20px auto 0;
  }
}

// Sticky Header
.pageHeader.sticky .headerContent .mainCallToAction {
  display: none;
}

// Mobile - Remove header Sticky
@media (max-width: 1024px) {
  body {
    margin-top: 0 !important;
  }
  .pageHeader,
  .pageHeader.sticky {
    position: relative !important;
  }
}

// Carousel Video - Play Button
.carouselSlideTitle.showsVideo:before {
  background-color: $white;
  color: $dark-blue;
  width: 80px;
  border-radius: 1px;
}
.carouselSlideTitle:hover.showsVideo:before {
  background-color: $dark-blue;
  color: $white;
}

// Carousel video pop up FIX - (Youtube vs Vimeo)
.embed-container.videoPopUpWrapper {
  padding-bottom: 56.25% !important;
  .youtube-player {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}
.videoPopupClose:hover:before {
  color: $dark-blue;
}

// Testimonials and Success Stories - No caps on Headings because quotes.
.PostCategory_testimonials .listedPostText h2,
.PostCategory_success-stories .listedPostText h2,
h2.carouselSlideHeading {
  text-transform: none !important;
}

// Footer Links
.footerBox a:hover {
  color: $blue;
}

// Footer - Logos
.pageFooterWrapper {
  overflow: hidden;
}
.footerBox[class*="ogos"] {
  position: relative;
  margin: $spacer 0 0 0;
  background-color: $white;
  padding: $spacer $site-bleed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1300px;
  z-index: 1;
  &:after {
    @include pseudo-element;
    height: 150%;
    width: 3000px;
    left: 50%;
    top: -7.5%;
    transform: translateX(-50%) rotate(2deg);
    background-color: $white;
    z-index: -1;
  }
  h3 {
    width: 100%;
    margin: 0 auto 1rem;
    font-size: $font-size-h4;
  }
  a {
    margin: 20px;
  }
  img {
    width: 100%;
    max-width: 85px;
    max-height: 85px;
    object-position: center center;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    a {
      margin: 14px;
    }
    img {
      max-width: 65px;
      max-height: 65px;
    }
  }
}

// Filter layout - FIX
.listedFilters li a {
  color: $body-colour;
}
.listedFilters li a:hover {
  color: $blue;
}
.listedFilters li {
  padding: 0.45em 0.9em;
}
.appliedFilters li .filter {
  color: $blue;
}
.categoryFilterContent > h2 {
  display: none;
}

// Client style table - From olf website
table.flexTable {
  margin: 20px 0;
  width: 100% !important;
  height: auto !important;
  tbody {
    border: none;
    display: flex;
    flex-wrap: wrap;
  }
  tr {
    width: auto !important;
    height: auto !important;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    flex: 1;
    img {
      margin: 0;
    }
  }
  tr:nth-child(odd) {
    background-color: transparent;
  }
  td {
    display: block; // Fixes an IE issue
    border-top: none;
    width: auto !important;
    height: auto !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0px;
  }
}

// Client style table - Justify the contents centrally
table.flexTable--central tbody {
  justify-content: center;
  text-align: center;
}

// Client style - Plain Button
a.plainButton {
  background-color: #241e46;
  color: white;
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  font-weight: $font-weight-bold;
  transition: 300ms all ease;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}
body.teensandtoddlers a.plainButton:hover {
  background-color: $pink !important;
}
body.youngleaders a.plainButton:hover {
  background-color: $orange !important;
}

// Inner page - Hover effect when <a> have img
a:hover {
  img {
    opacity: 0.85;
  }
}

// Cookie - Link
.cc-message p a {
  color: $yellow;
}

// Dashboard page - wider
body.impact-dashboard {
  .post .contentBlockWrapper,
  .post .headerText .title,
  .post .contentBlockWrapper .postContent {
    max-width: 1250px;
  }
}

// Homeapge carousel autoplay - Remove on mobile
@media (max-width: 768px) {
  body.homepage .carouselSlide .yt-container {
    display: none;
  }
}

// Inner content - iframe
.vimeoContainer {
  position: relative;
  display: block;
  height: 0;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Mailchimp CR-38
#mc_embed_signup_scroll {
  .indicates-required {
    display: none;
  }
  #mergeRow-gdpr {
    width: 100%;
    flex: unset;
    margin-top: 20px;
    .subfield {
      text-align: center;
      span {
        margin-left: 10px;
      }
    }
  }
  a {
    color: #fff;
  }
  .clear {
    width: 100%;
  }
}

// https://raisingit.atlassian.net/browse/DES-5896
.embed-container.videoPopUpWrapper {
  padding-bottom: 0 !important;
}

a.cta-button.donate.persist {
  //background: #262345;
  clip-path: polygon(2% 6%, 97% 0, 100% 100%, 0% 97%);
}

.mainCallToAction .cta-button:not([class*="donate"]){
  // background: $green;
  clip-path: polygon(2% 6%, 97% 0, 100% 100%, 0% 97%);
  // &:hover {
  //   background: $green
  // }
}

input#mc-embedded-subscribe {
  background: green;
  &:hover {
    background-color: #014705;
    border-color: #014705;
  }
}

.footerBox[class*="ewsletter"] {
  color: $yellow;
}

.quickGivingPanel .formQuestion.donationAmount {
  color: $yellow;
}