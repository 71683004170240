// Home features
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  )
}

// Specify a card layout for features
.homeFeatures .homeFeature {
  @include card;
  @include card-basic (
    $prefab: 3
  );
}


// Home feeds
@include home-feeds-prefab (
  $number-of-feeds: 3,
  $prefab: 1
);

// Home Feed 1 specific
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 2,
    $prefab: 3
  );
  .feedItem {
    @include card (
      $card-details-background-colour: $grey-lightest,
      $card-hover-details-background-colour: $grey-lightest,
      $card-border: none,
      $card-heading-text-transform: inherit
    );
    @include card-basic (
      $prefab: 3
    );
  }
}

// Home Feed 2 specific
.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 1,
    $prefab: 1
  );
}

// Home Feed 3 specific
.homeFeedBox3 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 2,
    $prefab: 4
  );
  .feedItem {
    @include card;
    @include card-basic (
      $prefab: 3
    );
  }
}


// Listed posts
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card (
    $card-details-background-colour: $grey-lightest,
    $card-hover-details-background-colour: $grey-lightest,
    $card-border: none,
    $card-heading-colour: $dark-blue,
    $card-hover-heading-colour: $dark-blue,
    $card-image-background-colour: $white,
    $card-hover-image-opacity: 1,
    $card-hover-image-scale: 1.05
  );
  @include card-basic (
    $prefab: 3
  );
  &.PostCategory_meet-the-team .listed-post-image-link {
    border: 6px solid $dark-blue;
  }
}


// Listed products
.listedProduct {
  @include card;
  @include card-basic (
    $prefab: 3
  );
}
