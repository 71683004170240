// Config values
$platform-name: "power2-redesign";
$assets-path: "../assets/";

// Colour presets
$green: #027908; //rgb(67, 182, 73);
$yellow: #EDF41F; //rgb(217, 224, 0);
$font-blue: rgb(38, 35, 69);
$dark-blue:#262345; //rgb(50, 43, 128);
$blue: rgb(27, 162, 221);
$pink: rgb(232, 85, 153);
$red: rgb(229, 39, 37);
$orange: rgb(251, 186, 7);

$gradient-green-yellow: linear-gradient(to right, $green 0%, $yellow 100%);
$gradient-dark-blue-blue: linear-gradient(to right, $dark-blue 0%, $blue 100%);
$gradient-dark-blue-pink: linear-gradient(to right, $dark-blue 0%, $pink 100%);
$gradient-red-orange: linear-gradient(to right, $red 0%, $orange 100%);

$gradient-green-yellow-green: linear-gradient(
  to right,
  $green 0%,
  $yellow 50%,
  $green 100%
);
$gradient-dark-blue-blue-dark-blue: linear-gradient(
  to right,
  $dark-blue 0%,
  $blue 50%,
  $dark-blue 100%
);
$gradient-dark-blue-pink-dark-blue: linear-gradient(
  to right,
  $dark-blue 0%,
  $pink 50%,
  $dark-blue 100%
);
$gradient-red-orange-red: linear-gradient(
  to right,
  $red 0%,
  $orange 50%,
  $red 100%
);

// Brand colours
$brand-primary: $green;
$brand-secondary: $yellow;
$donate-colour: $dark-blue;
// $brand-primary-text-contrast-override: null;
// $brand-secondary-text-contrast-override: null;

// Create greyscale
$black: #000;
$grey-dark: #222;
$grey: #444;
$grey-light: #666;
$grey-lighter: #ececec;
$grey-lightest: #f0f0f0;
$white: #fff;

// Layout
$container-max-width: 1440px;
$gap-width: 40px;
$site-bleed: 20px;

$border-radius: 0;
$border-colour: $grey-lighter;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 2rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $font-blue;
$profile-pics-enabled: true;
$border-width: 0px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 0.3s;
$transition-curve: ease-in-out;

// Logo
$logo: "logo_new.png"; // IE fix.
$logo-retina: "logo_new.png";
// $logo: "logo.svg";
$logo-width: 180px;
$logo-height: 135px;

$logo-absolute: false;
$logo-breakpoint: map-get($breakpoints, md);

$logo-mobile-width: $logo-width * 0.5;
$logo-mobile-height: $logo-height * 0.5;

// Typography
$font-family-base: "Montserrat", sans-serif;
$font-size-base: 1.05rem;
$font-size-large: 1.225em;
$font-size-small: 0.85em;

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$font-size-h1: 2.25em;
$font-size-h2: 1.85em;
$font-size-h3: 1.7em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.5em;
$headings-font-family: inherit;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.2;
$headings-colour: inherit;
$headings-text-transform: uppercase;
$headings-letter-spacing: -1px;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $dark-blue;
$link-decoration: none;
$link-hover-decoration: underline;
$link-hover-style: darken;

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: $green;
$btn-text-colour: $yellow;
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: $box-shadow;
$btn-padding-x: 1.8em;
$btn-padding-y: 0.9em;
$btn-font-family: $font-family-base;
$btn-font-weight: $font-weight-bold;
$btn-font-size: inherit;
$btn-text-transform: uppercase;
$btn-line-height: 1.25;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: $yellow;

// Social network colours
$social-icons-background-colour: transparent;
$social-icons-colour: $grey-light;
$social-icons-hover-colour: $green;
$social-icons-width: 37.5px;
$social-icons-height: 37.5px;
$social-icons-font-size: 1.45rem;
$social-icons-gap: 0px;
$social-icons-border-radius: 0;
$social-icons-box-shadow: none;

// Header specific
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $grey-light;
$social-icons-header-hover-colour: $blue;

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $body-colour;
$social-icons-footer-hover-colour: $blue;

// Share this page
$share-enabled: true;
$share-text-align: center;
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h4;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;

// Tables
$table-cell-padding: 0.75rem;

// Card
$card-gap-width: $gap-width;
$card-border-radius: $border-radius;
$card-border: 1px solid $border-colour;
$card-box-shadow: $box-shadow;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: $green;
$card-image-padding: 0;
$card-image-border-radius: 1px;
$card-details-padding: $spacer;
$card-details-background-colour: $white;
$card-details-background-gradient: none; // none or CSS gradient
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: $body-colour;
$card-heading-font-size: $font-size-h5;
$card-heading-margin-bottom: $spacer / 2;
$card-summary-enabled: true;
$card-summary-colour: text-contrast($card-details-background-colour);
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: rgba($black, 0.05);
$card-footer-background-gradient: none; // none or CSS gradient
$card-footer-colour: $body-colour;
$card-footer-padding: 0.5rem $card-details-padding;
$card-min-width: 280px;

// Card basic
$card-basic-image-position: above;

// Card text overlay
$card-text-overlay-show-summary-on-hover: true;
$card-text-overlay-summary-always-on: false;
$card-text-overlay-details-max-width: 100%;
$card-text-overlay-details-position-y: bottom; // top, center, bottom
$card-text-overlay-details-position-x: left; // left, center, right

// Card text over
$card-text-over-image-vertical-text-position: center; // top, center or bottom
$card-text-over-image-darken-image-amount: 0.5;
$card-text-over-image-tint-colour: $black;
$card-text-over-image-show-summary-on-hover: true;

// Card side by side
$card-side-by-side-image-position: left;
$card-side-by-side-image-width: 50%;
$card-side-by-side-grow-image: true; // Do you want the image to scale to 100% height of the overall card?
$card-side-by-side-vertical-text-position: top; // top, center or bottom
$card-side-by-side-breakpoint: map-get(
  $breakpoints,
  sm
); // When do you want to break the text under image?

// Card hover state
$card-hover-border-colour: $border-colour;
$card-hover-box-shadow: $card-box-shadow;
$card-hover-image-opacity: 0.8;
$card-hover-image-scale: 1;
$card-hover-details-background-colour: $card-details-background-colour;
$card-hover-details-background-gradient: $card-details-background-gradient; // none or CSS gradient
$card-hover-heading-colour: $green;
$card-hover-summary-colour: $body-colour;

// Event cards
$event-card-floating-date: false;
$event-card-floating-date-background-colour: $card-details-background-colour;
$event-card-floating-date-colour: text-contrast(
  $event-card-floating-date-background-colour
);
$event-card-floating-date-border-radius: $border-radius;
$event-card-floating-date-margin-y: $spacer;
$event-card-floating-date-margin-x: $spacer;

// Menu admin
$menu-admin-enabled: false;

// Header
$header-background-colour: $white;
$header-background-gradient: none;
$header-box-shadow: none;

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: $spacer * 0.5;
$header-content-margin-bottom: $spacer * 0.5;
$header-content-padding-top: 0;
$header-content-padding-bottom: 0;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $dark-blue;
$header-sticky-box-shadow: 0 0 10px rgba($black, 0.1);
$header-sticky-logo: null;
$header-sticky-logo-width: 0;
$header-sticky-logo-height: 0;
$header-sticky-menu-admin-enabled: false;
$header-sticky-search-enabled: false;
$header-sticky-social-icons-enabled: false;
$header-sticky-cta-btns-enabled: false;
$header-sticky-header-content-margin-top: 0;
$header-sticky-header-content-margin-bottom: 0;
$header-sticky-nav-margin-bottom: 0;

// Tagline
$tagline-enabled: false;

// Main call to action
$cta-btn-background-colour: $btn-background-colour;
$cta-btn-colour: $btn-text-colour;
$cta-btn-gap: 5px;
$cta-btn-breakpoint: map-get($breakpoints, sm);
$cta-donate-on-appeal: false;

// Search
$header-search-enabled: false;

// Navigation
$nav-type: normal;
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: $green;
$nav-text-transform: none;

// Top level items
$nav-top-level-item-padding: 15px 22.5px 10px 22.5px;
$nav-top-level-item-colour: $yellow;
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: $font-size-base;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-background-colour: transparent;
// $nav-top-level-item-hover-colour: $dark-blue;
$nav-top-level-item-hover-colour: $white;
$nav-top-level-item-hover-background-colour: rgba($black, 0.1);
// $nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: false;
$nav-top-level-first-item-home-icon: false;
$nav-top-level-first-item-home-icon-font-size: 20px;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $nav-background-colour;
$nav-submenu-box-shadow: none;
$nav-submenu-border-radius: $border-radius;
$nav-submenu-item-padding: 15px 20px;
$nav-submenu-item-colour: $yellow;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-bold;
$nav-submenu-item-hover-colour: $white;
$nav-submenu-item-hover-background-colour: rgba($black, 0.1);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $nav-submenu-item-colour;

// Burger button
$burger-btn-background-colour: $green;
$burger-btn-text-colour: $yellow;
$burger-btn-text-transform: $btn-text-transform;
$burger-btn-icon-breakpoint: 480px;
$burger-btn-bar-breakpoint: null;

// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: calc(1440px - 80px);
$nav-normal-align-items: center;
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: $nav-background-colour;
$nav-normal-mobile-top-level-item-colour: $yellow;
$nav-normal-mobile-submenu-background-colour: rgba($grey-lightest, 0.35);
$nav-normal-mobile-submenu-item-colour: $yellow;
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: $white;

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: linear-gradient(
  90deg,
  rgba(0, 0, 0, 0.75) 0%,
  rgba(0, 0, 0, 0) 75%
);
$carousel-contents-max-width: $container-max-width;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-position-x: left; // left, center, right
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: $box-shadow;
$carousel-details-max-width: 525px;
$carousel-details-min-height: auto;
$carousel-details-padding: 0;
$carousel-details-margin-y: 0;
$carousel-details-margin-x: 0px;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: $white;
$carousel-heading-font-size: $font-size-h2;
$carousel-summary-enabled: true;
$carousel-summary-colour: $white;
$carousel-summary-font-size: inherit;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: transparent;
$carousel-read-more-colour: $yellow;
$carousel-read-more-font-size: $carousel-summary-font-size;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: true;
$carousel-controls-button-size: 40px;
$carousel-controls-button-gap: 0px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-position-y: top;
$carousel-controls-position-x: split;
$carousel-controls-margin-y: $spacer;
$carousel-controls-margin-x: $spacer;
$carousel-controls-icon-colour: $white;
$carousel-controls-icon-font-size: 1.6em;

// Carousel buttons
$carousel-buttons-enabled: false;
$carousel-buttons-type: dots;

// Carousel background YouTube video
$carousel-yt-background-enabled: true;
$carousel-yt-background-size: "cover";
$carousel-yt-cover-padding-bottom: 56.25%;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $dark-blue;
$carousel-mobile-heading-colour: $white;
$carousel-mobile-summary-colour: $white;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: transparent;
$home-intro-content-max-width: 1080px;
$home-intro-content-align: center; // left, center or right
$home-intro-padding-y: $spacer;
$home-intro-margin-top: $spacer;
$home-intro-margin-bottom: 0;
$home-intro-text-align: center;
$home-intro-text-transform: none;
$home-intro-font-family: $headings-font-family;
$home-intro-font-size: 1.15em;
$home-intro-font-weight: 1.4;
$home-intro-line-height: $line-height-base;
$home-intro-colour: text-contrast($home-intro-background-colour);
$home-intro-border-radius: $border-radius;

// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: transparent;
$home-features-padding-top: $spacer * 2;
$home-features-padding-bottom: $spacer * 2;

// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer * 2;
$impact-stats-background-colour: $green;
$impact-stats-background-gradient: none;
$impact-stats-colour: $yellow;
$impact-stats-text-align: center;
$impact-stats-content-max-width: 1190px;
$impact-stats-breakpoint: map-get($breakpoints, md);

$impact-stats-overall-container-direction: column;
$impact-stats-direction: row;
$impact-stats-mobile-text-align: center;

// Heading
$impact-stats-heading-enabled: true;
$impact-stats-heading-font-size: $font-size-h2;
$impact-stats-heading-text-align: center;
$impact-stats-heading-colour: inherit;
$impact-stats-heading-margin-bottom: 0;

// Individual stat
$impact-stat-min-width: 180px;
$impact-stat-direction: column;
$impact-stat-padding: 0px 0px;
$impact-stat-margin: $gap-width;

// Figure
$impact-stats-figure-colour: $yellow;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 4em;

// Summary
$impact-stats-summary-colour: inherit;
$impact-stats-summary-font-size: $font-size-base;
$impact-stats-summary-font-weight: $font-weight-base;
$impact-stats-summary-margin-top: 0;

// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: $container-max-width;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: $spacer;
$home-quick-giving-padding-y: $spacer * 1.5;
$home-quick-giving-background-colour: $grey-lightest;
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: $donate-colour;
$home-quick-giving-donation-amount-border-width: 1px;
$home-quick-giving-donation-amount-border-colour: $donate-colour;
$home-quick-giving-donation-amount-selected-background-colour: $donate-colour;
$home-quick-giving-donation-amount-selected-figure-colour: $white;
$home-quick-giving-donate-btn-background-colour: transparent;
$home-quick-giving-donate-btn-colour: $white;
$home-quick-giving-donate-btn-border-colour: transparent;
$home-quick-giving-colour: $body-colour;
$home-quick-giving-heading-colour: $body-colour;
$home-quick-giving-heading-font-size: $font-size-h2;

// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: $white;
$home-feeds-padding-top: $spacer * 1.25;
$home-feeds-padding-bottom: $spacer;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h3;
$feeds-title-colour: $body-colour;
$feeds-title-text-align: center;

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;

// Footer
$footer-boxes-count: 5;
$footer-prefab: 1;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 2.5;
$footer-background-colour: $grey-lightest;
$footer-background-gradient: none; // none or CSS gradient
$footer-colour: text-contrast($footer-background-colour);
$footer-link-colour: $dark-blue;
$footer-text-align: center;
$footer-font-size: $font-size-base;
$footer-headings-font-size: $font-size-h5;

// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-background-gradient: $dark-blue;
$newsletter-colour: $white;
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h2;
$newsletter-heading-text-align: center; // left, center, right
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer;
$newsletter-heading-colour: $white;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $brand-secondary;
$newsletter-button-colour: text-contrast($newsletter-button-background-colour);
$newsletter-button-border-colour: $brand-secondary;
$newsletter-use-placeholders: false; // Turn on to use placeholders in the inputs rather than labels

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: $spacer * 3;
$newsletter-hero-contents-max-width: $footer-contents-max-width;

// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer;
$context-container-border-radius: 0;

// Posts
$post-content-max-width: 1080px;

// Blockquote
$blockquote-max-width: $post-content-max-width;
$blockquote-background-colour: $dark-blue;
$blockquote-background-gradient: none;
$blockquote-border-radius: 0;
$blockquote-margin-y: $spacer;
$blockquote-padding: $spacer $spacer * 0.75;
$blockquote-text-align: left;
$blockquote-font-size: 1.4em;
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: $yellow;
$blockquote-box-shadow: none;

// Header text
$header-text-margin-top: $spacer * 1.5;
$header-text-margin-bottom: $spacer;

// Page title
$page-title-font-size: $font-size-h2;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $headings-colour;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left;
$page-title-margin-top: 0;
$page-title-margin-bottom: $headings-margin-bottom;

// Sidebar
$sidebar-enabled: false;

// Breadcrumb
$breadcrumb-enabled: true;
// $breadcrumb-margin-bottom: $spacer * 0.5;

// Donation amounts
$donation-amount-min-width: 270px;

// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-background-colour: $grey-lightest;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-font-size: $font-size-h4;
$faq-item-btn-background-colour: transparent;
$faq-item-btn-chevron-colour: $dark-blue;
$faq-item-hover-btn-background-colour: transparent;
$faq-item-hover-btn-chevron-colour: $dark-blue;

// Product-price
$product-price-colour: $dark-blue;
$product-price-font-size: $font-size-h3;

// Cookie consent
$cookie-consent-position: bottom;
$cookie-consent-background-colour: rgba($black, 0.85);
$cookie-consent-colour: $white;
$cookie-consent-btn-background-colour: transparent;
$cookie-consent-btn-text-colour: $white;
